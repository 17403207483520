import React, { Component } from 'react';
import CustomTable from '../components/CustomTable';
import LoadingSpinner from '../components/LoadingSpinner';
import { postUser, updateUser, getUsersParams, postUserOld, getUsersOld, getPdfQuestionForm, deleteUser, clinicDeleteUser } from '../services/http/authService';
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';
import { getCurrentUser } from '../services/http/authService';
import { DISALLOWED_EMAILS_ANTIGEN } from '../constants/controllers';
import EditPatientModal from '../components/EditPatientModal';
import PreviewPatientModal from '../components/PreviewPatientModal';
import { isMobile } from 'react-device-detect';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorToast, successToast } from '../constants/toasts';
import { Col, Row } from 'react-bootstrap';
import PDFPreviewModal from '../components/PDFPreviewModal';

export default class AllPatients extends Component {

    PER_PAGE = 50;

    state = {
      isLoading: false,
      isTableLoading: false,
      isPrintingLoading: false,
      display: 0, // 0 - pending, 1 - resolved
      test_type_display: 0, // 0 - PCR, 1 - ANTIGEN
      first_name: '',
      last_name: '',
      passport_num: '',
      email: '',
      phone: '',
      date_of_birth: '',
      date_of_test: Date.now(),
      test_maker: '',
      test_type: '',
      test_reason: null,
      is_positive: null,
      gender: '',
      users: [],
      usersDisplayed: [],
      sort_asc: false,
      last_sort: '',
      protocol_num: null,
      activePage: 1,
      last_user: null,
      user: null,
      isEditModalShown: false,
      isPreviewModalShown: false,
      isPDFPreviewModalShown: false,
      pdfModalURL: null,
      modalPatient: null // patient object sent to the modal for editting
    };
  
    componentDidMount() {
      // window.scrollTo(0, 0);
      const user = getCurrentUser();
      this.setState({ user });
      if (DISALLOWED_EMAILS_ANTIGEN.includes(user.email)) {
        this.setState({ test_type: "PCR" });
      }
      this.getUsersOlder();
    }

    getUsersOlder = () => {
      this.setState({ isLoading: true });
      getUsersOld().then(this.onGetUsersSuccess).catch(this.onGetUsersError);
    }

    onGetUsersError = (err) => {
      this.setState({ isLoading: false, isTableLoading: false });
      if (err && err.message) {
        if (err.message.includes('No news') || err.message.includes('Failed to fetch')) {
          return;
        }
      }
      errorToast(err.messag);
    };
  
    onGetUsersSuccess = (res) => {
      const pageNumber = this.state.activePage;
      const usersArray = res.result.reverse()
      this.setState({
        users: usersArray,
        activePage: pageNumber,
        usersDisplayed: usersArray.slice(this.getStartIndex(pageNumber), this.getEndIndex(pageNumber)),
        isLoading: false,
        isTableLoading: false
      });
    };

    getStartIndex = (pageNumber) => {
      return (pageNumber - 1) * this.PER_PAGE;
    }
  
    getEndIndex = (pageNumber) => {
      return pageNumber * this.PER_PAGE;
    }

    handlePageChange(pageNumber) {
      console.log(`active page is ${pageNumber}`);
      this.setState({ activePage: pageNumber, usersDisplayed: this.state.users.slice(this.getStartIndex(pageNumber), this.getEndIndex(pageNumber)) });
    }

    sortUsers = (e, by) => {
      e.preventDefault();
      const { usersDisplayed, last_sort, sort_asc } = this.state;
      let sorted;
      let sort_asc_state = sort_asc;
      if (by == last_sort) {
        sort_asc_state = !sort_asc_state;
      }
      sorted = usersDisplayed.sort((a, b) => {
        if (a[by] < b[by]) {
          return sort_asc_state ? -1 : 1;
        }
        if (a[by] > b[by]) {
          return sort_asc_state ? 1 : -1;
        }
        return 0;
      });
      this.setState({ usersDisplayed: sorted, last_sort: by, sort_asc: sort_asc_state });
    };

    handleEditClick = (e, patient) => {
      e.preventDefault();
      this.setState({ isEditModalShown: true, modalPatient: patient });
    }

    handlePreviewClick = (e, patient) => {
      e.preventDefault();
      this.setState({ isPreviewModalShown: true, modalPatient: patient });
    }

    handlePDFClick = (e, patient) => {
      e.preventDefault();
      this.setState({ isPDFPreviewModalShown: true, pdfModalURL: patient.pdf_link_primary });
    }

    handlePrintQuestionnaireClicked = (e, user) => {
      this.setState({ isLoading: true });
      getPdfQuestionForm(user.id)
        .then((res) => {
          this.onPdfQuestionFormSuccess(res.result);
        })
        .catch((err) => {
          this.onPdfQuestionFormFailed(err.message);
        });
    }

    handleUserDelete = (e, user) => {
      e.preventDefault();
      if (window.confirm(`Da li ste sigurni da želite sakriti pacijenta '${user.first_name} ${user.last_name}' iz tabele`)) {
        this.setState({ isLoading: true });
        clinicDeleteUser(user)
          .then((res) => {this.onUserDeleteSuccess(res.result)})
          .catch((err) => {this.onUserDeleteError(err.message)});
      }
    }

    hidePDFPreviewModal = () => {
      this.setState({ isPDFPreviewModalShown: false, pdfModalURL: null });
    }

    render() {
      const { display, users, usersDisplayed, activePage, lastUser, test_type_display } = this.state;
      return (
        <div className="main-cont">
          <ToastContainer
            newestOnTop
            limit={5}
          />
          {this.state.isLoading == true || this.props.transportFormLoading == true ? (
            <div className="text-center" style={{ marginTop: '150px', marginBottom: '150px' }}>
              <LoadingSpinner height={100} width={100} text="Učitavanje u toku. Molimo nemojte zatvarati ovaj prozor..." />
            </div>
          ) : (
            <div>
              <br></br>
              <div class="section-title" data-aos="fade-up">
                <h2>Svi pacijenti</h2>
              </div>
              <div class="section-title" data-aos="fade-up">
                <p style={{ color: 'rgb(113 113 113)', fontSize: "14px", marginTop: "-20px" }}>novododani pacijenti se pojavljuju na vrhu prve stranice</p>
              </div>
              <section id="featured" class="featured" style={{ paddingTop: "0px" }}>
                <div class="container-fluid">
                  <div class="row"></div>
                  {this.state.isTableLoading == true ? (
                    <div className="text-center" style={{ marginTop: '100px', marginBottom: '100px' }}>
                      <LoadingSpinner height={100} width={100} text="Učitavanje pacijenata..." />
                    </div>) :
                    <div class="row" style={{ marginTop: "0px" }}>
                      <div class="col-md-12">
                        <CustomTable
                          type={display}
                          user={this.state.user}
                          users={users}
                          usersDisplayed={usersDisplayed}
                          activePage={activePage}
                          perPage={this.PER_PAGE}
                          testType={this.state.test_type_display}
                          handlePageChange={this.handlePageChange.bind(this)}
                          updateUserTest={null}
                          sortUsers={this.sortUsers.bind(this)}
                          handleEditClick={this.handleEditClick.bind(this)}
                          handlePreviewClick={this.handlePreviewClick.bind(this)}
                          handlePDFClick={this.handlePDFClick.bind(this)}
                          handlePrintQuestionnaireClicked={this.handlePrintQuestionnaireClicked.bind(this)}
                          isPrintingLoading={this.state.isPrintingLoading}
                          handleUserDelete={this.handleUserDelete.bind(this)}
                        ></CustomTable>
                      </div>
                    </div>
                  }
                  {/* <EditPatientModal
                    isEditModalShown={this.state.isEditModalShown}
                    hideEditModal={this.hideEditModal}
                    user={this.state.user}
                    patient={this.state.modalPatient}
                    onSendClick={this.onNewUserSendClick}
                  />
                  <PreviewPatientModal
                    isPreviewModalShown={this.state.isPreviewModalShown}
                    hidePreviewModal={this.hidePreviewModal}
                    user={this.state.user}
                    patient={this.state.modalPatient}
                  /> */}
                  <PDFPreviewModal 
                    isPreviewModalShown={this.state.isPDFPreviewModalShown}
                    hidePreviewModal={this.hidePDFPreviewModal}
                    pdfURL={this.state.pdfModalURL}
                  />
                </div>
              </section>
  
            </div>
          )}
        </div>
      );
    }

}