import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import dateFormat from 'dateformat';

export default class CustomTable extends Component {

  state = {};

  componentDidUpdate() { }

  onPositive(e, user) {
    const prefix = "Jeste li sigurni da je pacijent ";
    const infix = user.first_name + " " + user.last_name + " ";
    const suffix = "POZITIVAN?";
    let date = new Date();
    if (user.status = "PENDING" && user.date_of_issuing_report_datetime && user.date_of_issuing_report) {
      date = user.date_of_issuing_report_datetime;
    }
    if (window.confirm(prefix + infix + suffix)) {
      this.props.updateUserTest(user, true, dateFormat(date, "dd/mm/yyyy HH:MM"));
    }
  }

  onNegative(e, user) {
    const prefix = "Jeste li sigurni da je pacijent ";
    const infix = user.first_name + " " + user.last_name + " ";
    const suffix = "NEGATIVAN?";
    let date = new Date();
    if (user.status = "PENDING" && user.date_of_issuing_report_datetime && user.date_of_issuing_report) {
      date = user.date_of_issuing_report_datetime;
    }
    if (window.confirm(prefix + infix + suffix)) {
      this.props.updateUserTest(user, false, dateFormat(date, "dd/mm/yyyy HH:MM"));
    }
  }

  mapUsers = (users) => {
    if (!users) return <div></div>
    return users.map((user) => {
      return <tr style={{ width: '100%' }}>
        <td>{user.id}</td>
        <td>{user.first_name}</td>
        <td>{user.last_name}</td>
        <td>{user.passport_num ? user.passport_num : '/'}</td>
        <td>{user.email ? user.email : '/'}</td>
        <td>{user.phone}</td>
        <td>{user.date_of_test}</td>
        <td>{user.test_type}</td>
        {/* <td><a href="#" onClick={(e) => this.props.handlePreviewClick(e, user)}>Pregledaj</a></td>
        <td>{user.date_of_issuing_report}</td> */}
        {/* <td>{status}</td> */}
        <td style={{ wordWrap: 'break-word' }}>
          <a href="#" onClick={(e) => this.props.handlePDFClick(e, user)}>LINK</a>
        </td>
        <td style={{ wordWrap: 'break-word' }}>{user.pdf_link_en ? <a href={user.pdf_link_en}>LINK</a> : '/'}</td>

        {/* {this.props.testType == 0 && <td><a href="#" onClick={(e) => this.props.handleEditClick(e, user)}>Izmijeniti</a></td>} */}
      </tr>
    });
  };

  render() {
    const { users, usersDisplayed, activePage, perPage, type, testType } = this.props;
    return <div class="container-fluid">

      <div class="page">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={perPage}
          totalItemsCount={users.length}
          pageRangeDisplayed={8}
          onChange={this.props.handlePageChange.bind(this)}
          itemClassNext="next-page"
          itemClassLast="last-page"
          itemClassPrev="prev-page"
          itemClassFirst="first-page"
          prevPageText="<"
          nextPageText=">"
        />
      </div>

      <table border="1">
        <tr>
          <th>ID</th>
          <th>Ime</th>
          <th
            onClick={(e) => {
              this.props.sortUsers(e, 'last_name');
            }}
          >
            Prezime&#9662;
          </th>
          <th>Pasoš</th>
          <th>Email</th>
          <th>Telefon</th>
          <th
            onClick={(e) => {
              this.props.sortUsers(e, 'date_of_test');
            }}
          >
            Datum testa&#9662;
          </th>
          <th>Vrsta testa</th>
          <th>PDF</th>
          <th>PDF (engleski)</th>

        </tr>
        {this.mapUsers(usersDisplayed)}
      </table>


    </div>
  }
}