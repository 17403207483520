export const HOME_ROUTE = '/home';
export const SIGNIN_ROUTE = '/sign-in';
export const REPORTS_ROUTE = '/reports';
export const LAB_ROUTE = '/lab';
export const CLINIC_ROUTE = '/clinic';
export const ALL_PATIENTS_ROUTE = '/all-patients';

export const TEST_ROUTE = 'http://localhost:3001/api';
export const BASE_API_ROUTE = 'https://api.medical-centar.ba/api';
export const TEST_DYNAMIC_ROUTE = 'https://testdynamic.medical-centar.ba/api';
export const BASE_API_ROUTE_2 = 'https://api2.medical-centar.ba/api';
