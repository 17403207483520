import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar';
import ProtectedLabRoute from './components/ProtectedLabRoute';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedSuperuserRoute from './components/ProtectedSuperuserRoute';
import { SIGNIN_ROUTE, REPORTS_ROUTE, LAB_ROUTE, CLINIC_ROUTE, ALL_PATIENTS_ROUTE } from './constants/routes';
import Clinic from './pages/Clinic';
import Lab from './pages/Lab';
import Reports from './pages/Reports';
import SignIn from './pages/SignIn';
import { getCurrentUser, createTransportReport, createPCRAllReport } from './services/http/authService';
import { Button, Modal } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import { isMobile } from 'react-device-detect';
import { errorToast, successToast } from './constants/toasts';
import PDFMerger from 'pdf-merger-js/browser';
import PDFPreviewModal from './components/PDFPreviewModal';
import AllPatients from './pages/AllPatients';

class App extends Component {
  state = {
    transportFormLoading: false,
    isTransportFormModalShown: false,
    isPCRAllModalShown: false,
    PCRAllUrl: null,
    reportInfo: {
      start_date: '',
      end_date: '',
      isToday: true
    }
  };

  componentDidMount() {
    const user = getCurrentUser();
    this.setState({ user });
  }

  onTransportFormClick(e) {
    this.setState({ isTransportFormModalShown: true });
  }

  hideTransportFormModal = () => {
    this.setState({ isTransportFormModalShown: false });
  }

  hidePCRAllModal = () => {
    this.setState({ isPCRAllModalShown: false });
  }

  onCreateTransportForm = (e) => {
    this.setState({ transportFormLoading: true, isTransportFormModalShown: false });
    createTransportReport(this.state.reportInfo)
      .then((res) => {
        this.onPostSuccess(res.result);
      })
      .catch((err) => {
        this.onPostFailed(err.message);
      });
  }

  onPostSuccess = (res) => {
    this.setState({ transportFormLoading: false });
    let out = res.path;
    successToast("Transportni list uspješno kreiran. Odobrite popups na vašem web pretraživaču da bi vam se transportni list automatski otvorio.")
    if (!isMobile) {
      window.open(out, '_blank', 'noopener,noreferrer');
    }
    else window.location.assign(out)

    this.setState({
      reportInfo: {
        start_date: '',
        end_date: '',
        isToday: true
      }
    });
  };

  onPostFailed = (err) => {
    this.setState({ transportFormLoading: false });
    console.log(err);
    errorToast('GREŠKA: ' + err);
    this.setState({
      reportInfo: {
        start_date: '',
        end_date: '',
        isToday: true
      }
    });
  };

  handleSelectReportToday = (e) => {
    this.setState({ reportInfo: { ...this.state.reportInfo, isToday: e.target.value == "true" } });
  };

  handleDateChange = (e, property) => {
    // this.setState({ [property]: e });
    const startDate = e;
    const endDate = new Date();
    endDate.setFullYear(e.getFullYear());
    endDate.setMonth(e.getMonth());
    endDate.setDate(e.getDate());
    endDate.setSeconds(59);
    endDate.setMinutes(59);
    endDate.setHours(23);
    this.setState({ reportInfo: { ...this.state.reportInfo, start_date: startDate, end_date: endDate } });
  };

  OnAllTodayPCRClick = async (e) => {
    e.preventDefault();
    this.setState({ transportFormLoading: true });

    createPCRAllReport({ isToday: true, start_date: '', end_date: '' })
      .then((res) => {
        this.onAllTodayPCRSuccess(res.result);
      })
      .catch((err) => {
        this.onAllTodayPCRFailed(err.message);
      });
  }

  onAllTodayPCRSuccess = (res) => {
    this.setState({ transportFormLoading: false, PCRAllUrl: res.path, isPCRAllModalShown: true });
    successToast("Izvještaj uspješno kreiran");
  };

  onAllTodayPCRFailed = (err) => {
    this.setState({ transportFormLoading: false });
    console.log(err);
    errorToast('GREŠKA: ' + err);
  };

  OnAllPatientsClicked = async (e) => {
    
  }

  render() {
    const { user } = this.state;
    return (
      <React.Fragment>
        {/* <HashRouter> */}
        {user && <Navbar user={user} onTransportFormClick={this.onTransportFormClick.bind(this)} OnAllTodayPCRClick={this.OnAllTodayPCRClick.bind(this)}/>}
        <main className="container-fluid">
          <Switch>
            <ProtectedSuperuserRoute exact path={REPORTS_ROUTE} component={Reports} />
            <ProtectedRoute exact path={CLINIC_ROUTE} component={Clinic} transportFormLoading={this.state.transportFormLoading} />
            <ProtectedRoute exact path={ALL_PATIENTS_ROUTE} component={AllPatients} transportFormLoading={this.state.transportFormLoading} />
            <ProtectedLabRoute exact path={LAB_ROUTE} component={Lab} />
            <Route path={SIGNIN_ROUTE} component={SignIn} />

            <Redirect from="/" exact to={SIGNIN_ROUTE} />
          </Switch>
        </main>
        {/* </HashRouter> */}

        <Modal show={this.state.isTransportFormModalShown} onHide={this.hideTransportFormModal}>
          <Modal.Header>
            <Modal.Title>Transportni list</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Transportni list prikazuje listu pacijenata koji se nalaze u "na čekanju" tabeli, koji su radili PCR test i koji nisu verificirani</p>
            <label>
              <b>Izvještaj za danas</b>
            </label>
            <div onChange={this.handleSelectReportToday}>
              <input type="radio" value="true" name="isToday" defaultChecked /> DA {'             '}
              <input type="radio" value="false" name="isToday" style={{ marginLeft: '20px' }} /> NE
            </div>
            {this.state.reportInfo.isToday == false &&
              <div>
                <label for="start_date">Odaberite datum</label>
                <br></br>
                <DateTimePicker
                  onChange={(e) => this.handleDateChange(e, 'start_date')}
                  value={this.state.reportInfo.start_date}
                  format="dd/MM/y"
                  disableClock="true"
                />
                <br></br>
                <br></br>
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.hideTransportFormModal}>
              Nazad
            </Button>
            <Button variant="primary" onClick={this.onCreateTransportForm}>
              Dalje
            </Button>
          </Modal.Footer>
        </Modal>

        <PDFPreviewModal
          isPreviewModalShown={this.state.isPCRAllModalShown}
          hidePreviewModal={this.hidePCRAllModal}
          pdfURL={this.state.PCRAllUrl}
        />

      </React.Fragment>
    );
  }
}

export default App;
