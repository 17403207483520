import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import { TOKEN_HEADER } from '../constants/headers';
import { ALL_PATIENTS_ROUTE, CLINIC_ROUTE, SIGNIN_ROUTE } from '../constants/routes';
import { errorToast, successToast } from '../constants/toasts';
import { signOut } from '../services/http/authService';

export default class NavBar extends Component {
  onSignOut = () => {
    signOut()
      .then((res) => this.onSignOutSuccessful())
      .catch((err) => this.onSignOutError(err));
  };

  onSignOutSuccessful = () => {
    localStorage.removeItem(TOKEN_HEADER);
    successToast('Uspješno ste odjavljeni');
    window.location = SIGNIN_ROUTE;
  };

  onSignOutError = (error) => {
    errorToast(error);
    localStorage.removeItem(TOKEN_HEADER);
    window.location = SIGNIN_ROUTE;
  };

  onAllPatients = () => {
    window.location = ALL_PATIENTS_ROUTE;
  }

  onAddPatient = () => {
    window.location = CLINIC_ROUTE;
  }

  render() {
    return (
      <header id="header">
        <div className="container d-flex">
          <div className="logo mr-auto">
            <h1>
              <span>
                {this.props.user.name} {this.props.user.surname}
              </span>
            </h1>
            {/* <a href={HOME_ROUTE}>
              <img src={logo} alt="" className="img-fluid" />
            </a> */}
          </div>

          <Navbar expand="md">
            {/* <a className="navbar-brand" href={HOME_ROUTE}> */}
            {/* <Logo /> */}
            {/* </a> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <nav className="nav-menu">
                <ul>
                  <li className="logout" style={{ marginRight: "20px" }} onClick={this.onAddPatient}>
                      Dodaj pacijenta
                  </li>
                  <li className="logout" style={{ marginRight: "20px" }} onClick={this.onAllPatients}>
                      Svi pacijenti
                  </li>
                  {/* {this.props.user == null || (this.props.user.lab_id == null || this.props.user.lab_id <= 0) && !this.props.user.superuser &&
                    <li className="logout" onClick={this.props.OnAllTodayPCRClick} style={{ marginRight: "20px" }}>
                      Današnji PCR nalazi
                    </li>
                  }
                  {this.props.user == null || (this.props.user.lab_id == null || this.props.user.lab_id <= 0) && !this.props.user.superuser &&
                    <li className="logout" onClick={this.props.onTransportFormClick}>
                      Transportni List
                    </li>
                  } */}
                  <li className="logout" style={{ paddingLeft: "20px" }} onClick={this.onSignOut}>
                    Odjavi se
                  </li>
                </ul>
              </nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
    );
  }
}
