import fetch from 'node-fetch';
import { getJwt } from './authService';

function validateStatus(result) {
  const { status, message } = result;
  if (status) {
    throw new Error(message);
  }
}

function resolveResult(res, headers, isFile = false) {
  const result = JSON.parse(res);
  validateStatus(result);
  return {
    result,
    headers,
  };
}

async function handleResponse(res, isFile = false) {
  const { headers } = res;
  res = await res.text();
  if (res.includes('ECONNREFUSED')) throw new Error('Could not connect to server');
  return resolveResult(res, headers, isFile);
}

/**
 * GET request
 *
 * @param {String} path Path to get
 */
export function get(path) {
  const token = '';
  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'x-auth': getJwt() },
  };
  console.log("getting users");
  return fetch(path, request).then(async (res) => {
    return await handleResponse(res);
  });
}

export function downloadFile(path, data) {
  let request = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/pdf',
    },
  };
  return fetch(path, request).then(async (res) => {
    return await handleResponse(res, true);
  });
}

/**
 * POST request
 *
 * @param {String} path Path to post
 * @param {Object} data Data to post
 * @param {Object} query Query params
 */
export function post(path, data, query = {}, includeJwt = false) {
  let request = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (includeJwt) {
    const jwt = getJwt();
    if (jwt != null) {
      request['headers']['x-auth'] = getJwt();
    }
  }
  console.log("Posting user:")
  console.log(request);
  return fetch(path, request).then(async (res) => {
    console.log("RES");
    console.log(JSON.stringify(res, null, 2));
    return await handleResponse(res);
  });
}

export function put(path, data = {}, includeJwt = false) {
  const request = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  };
  if (includeJwt) {
    const jwt = getJwt();
    if (jwt != null) {
      request['headers']['x-auth'] = getJwt();
    }
  }
  return fetch(path, request).then(async (res) => {
    return await handleResponse(res);
  });
}

/**
 * DELETE request
 *
 * @param {String} path Path to delete
 * @param {Object} data Data containing headers
 * @param {Object} query Query params
 */
export function deleteCall(path, data, query = {}) {
  const request = {
    method: 'DELETE',
    headers: data.headers,
    body: JSON.stringify(data.body)
  };
  return fetch(path, request).then(async (res) => {
    if (res.status !== 200) {
      return await resolveResult(res);
    }
    return res;
  });
}

